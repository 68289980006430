import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Box } from 'rebass';
import slugify from '@sindresorhus/slugify';

import Layout from '../components/Layout';
import { cartesian } from '../utils/randomize';
import { ArticleContainer } from '../components/ui/ArticleContainer';
import { CTA } from '../components/ui/CTA';
import Button from '../components/ui/Button';

const CITIES_WITH_MORE_THAN_50K_INHABITANTS = [
  'Paris',
  'Argenteuil',
  'Boulogne-Billancourt',
  'Saint-Denis',
  'Montreuil',
  'Nanterre',
  'Vitry-sur-Seine',
  'Créteil',
  'Asnières-sur-Seine',
  'Aubervilliers',
  'Versailles',
  'Aulnay-sous-Bois',
  'Courbevoie',
  'Colombes',
  'Rueil-Malmaison',
  'Issy-les-Moulineaux',
  'Noisy-le-Grand',
  'Levallois-Perret',
  'Cergy',
  'Clichy',
  'Antony',
  'Pantin',
  'Meaux',
  'Épinay-sur-Seine',
  'Maisons-Alfort',
  'Évry',
  'Bobigny',
  'Marseille',
  'Lyon',
  'Toulouse',
  'Nice',
  'Nantes',
  'Montpellier',
  'Strasbourg',
  'Bordeaux',
  'Lille',
  'Rennes',
  'Reims',
  'Le Havre',
  'Saint-Étienne',
  'Toulon',
  'Grenoble',
  'Dijon',
  'Angers',
  'Nîmes',
  'Villeurbanne',
  'Le Mans',
  'Clermont-Ferrand',
  'Brest',
  'Limoges',
  'Amiens',
  'Annecy',
  'Metz',
  'Besançon',
  'Orléans',
  'Mulhouse',
  'Caen',
  'Nancy',
];

const QUARTER_LABELS = {
  1: 'premier',
  2: 'deuxième',
  3: 'troisième',
  4: 'dernier',
};

function DataCell({
  bold = false,
  children,
}: {
  bold: boolean;
  children: React.ReactNode;
}) {
  return <td>{bold ? <b>{children}</b> : children}</td>;
}

function TemplateContent({
  h1,
  firstParagraph,
  secondParagraph,
  thirdAndFourthParagraphs,
  helmet,
  pageContext,
}) {
  const shouldDisplayOnlyDepartementCode = pageContext.department.code === '79';
  const departementCodeAndNameOrOnlyDepartementCode = shouldDisplayOnlyDepartementCode
    ? pageContext.department.code
    : `${pageContext.department.code} (${pageContext.department.nom})`;

  const dangerData = [
    ...(pageContext.department.dangerRank > 3
      ? [
          {
            dep: `Départment ${departementCodeAndNameOrOnlyDepartementCode}`,
            cases: `${pageContext.department.casesPerThousand
              .toString()
              .replace('.', ',')} ‰`,
            rank: pageContext.department.dangerRank,
          },
        ]
      : []),
    {
      dep: 'Départment 93 (Seine-Saint-Denis)',
      cases: '84,73 ‰',
      rank: 1,
    },
    {
      dep: 'Départment 13 (Bouches-du-Rhône)',
      cases: '76,51 ‰',
      rank: 2,
    },
    {
      dep: 'Départment 06 (Alpes-Maritimes)',
      cases: '72,62 ‰',
      rank: 3,
    },
  ];

  const theftData = [
    ...(pageContext.department.theftRank > 3
      ? [
          {
            dep: `Départment ${departementCodeAndNameOrOnlyDepartementCode}`,
            cases: pageContext.department.theftCount,
            rank: pageContext.department.theftRank,
          },
        ]
      : []),
    {
      dep: 'Départment 59 (Nord)',
      cases: 13380,
      rank: 1,
    },
    {
      dep: 'Départment 13 (Bouches-du-Rhône)',
      cases: 13081,
      rank: 2,
    },
    {
      dep: 'Départment 75 (Paris)',
      cases: 12256,
      rank: 3,
    },
  ];

  const offenseData = [
    ...(pageContext.department.offenseRank > 3
      ? [
          {
            dep: `Départment ${departementCodeAndNameOrOnlyDepartementCode}`,
            cases: pageContext.department.offenseCount,
            rank: pageContext.department.offenseRank,
          },
        ]
      : []),
    {
      dep: 'Départment 75 (Paris)',
      cases: 200248,
      rank: 1,
    },
    {
      dep: 'Départment 13 (Bouches-du-Rhône)',
      cases: 91906,
      rank: 2,
    },
    {
      dep: 'Départment 59 (Nord)',
      cases: 85176,
      rank: 3,
    },
  ];
  const slug = `${slugify(pageContext.department.region.nom)}/${slugify(
    pageContext.department.nom
  )}`;

  return (
    <ArticleContainer>
      <article className="mx-auto prose prose-lg text-gray-600 prose-indigo">
        {helmet || ''}
        <h1 id={slugify(h1)}>
          <span className="block mb-4 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
            {h1}
          </span>
        </h1>
        <h2>
          Agents de sécurité dans le département{' '}
          {departementCodeAndNameOrOnlyDepartementCode}
        </h2>
        <Box mt={50}>{firstParagraph}</Box>
        <CTA
          href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
        >
          Demander un devis gratuit
        </CTA>
        <Box my={4} />
        {secondParagraph}
        <Box my={4} />
        {thirdAndFourthParagraphs}
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Demander un devis gratuit
          </CTA>
        </Box>
        <h2>
          Informations concernant les entreprises de sécurité et l’insécurité
          dans le département {departementCodeAndNameOrOnlyDepartementCode}
        </h2>
        <Box my={4} />
        <h3>
          Nombre de sociétés de sécurité dans le département{' '}
          {departementCodeAndNameOrOnlyDepartementCode}
        </h3>
        <p>
          Nous avons dénombré{' '}
          <b>
            {pageContext.department.companyCount} sociétés de sécurité privée
          </b>{' '}
          dans le département {departementCodeAndNameOrOnlyDepartementCode}.
        </p>
        <h3>
          Statistiques concernant l’insécurité dans le département{' '}
          {departementCodeAndNameOrOnlyDepartementCode}
        </h3>
        <p>
          Pour vous donner une idée du niveau d’insécurité dans le département{' '}
          {pageContext.department.code}, nous avons compilé quelques chiffres :
        </p>
        <h4>Nombre de crimes et délits pour 1000 habitants recensés en 2014</h4>
        <table>
          <thead>
            <tr>
              <th>Département</th>
              <th>Nombre de cas pour 1000 habitants</th>
              <th>Rang</th>
            </tr>
          </thead>
          {dangerData.map((line) => (
            <tr key={line.rank}>
              <DataCell bold={line.rank === pageContext.department.dangerRank}>
                {line.dep}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.dangerRank}>
                {line.cases}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.dangerRank}>
                {line.rank}
              </DataCell>
            </tr>
          ))}
        </table>
        <i>Source: linternaute.com</i>
        <Box my={4} />
        <h4>Nombre de cambriolages recensés en 2015</h4>
        <table>
          <thead>
            <tr>
              <th>Département</th>
              <th>Nombre de cambriolages</th>
              <th>Rang</th>
            </tr>
          </thead>
          {theftData.map((line) => (
            <tr key={line.rank}>
              <DataCell bold={line.rank === pageContext.department.theftRank}>
                {line.dep}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.theftRank}>
                {line.cases}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.theftRank}>
                {line.rank}
              </DataCell>
            </tr>
          ))}
        </table>
        <i>Source: securitasdirect.fr</i>
        <Box my={4} />
        <h4>Nombre de crimes et délits recensés en 2016</h4>
        <table>
          <thead>
            <tr>
              <th>Département</th>
              <th>Nombre de crimes et délits</th>
              <th>Rang</th>
            </tr>
          </thead>
          {offenseData.map((line) => (
            <tr key={line.rank}>
              <DataCell bold={line.rank === pageContext.department.offenseRank}>
                {line.dep}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.offenseRank}>
                {line.cases}
              </DataCell>
              <DataCell bold={line.rank === pageContext.department.offenseRank}>
                {line.rank}
              </DataCell>
            </tr>
          ))}
        </table>
        <i>Source: securitasdirect.fr</i>
        <Box my={4} />
        <p>
          Par « crimes et délits », nous entendons : les violences, les
          agressions, les vols et dégradations de bien, les cambriolages, les
          trafics de drogues, le proxénétisme, et le port d’armes prohibées. En
          2014, le département {departementCodeAndNameOrOnlyDepartementCode} se
          situait dans le{' '}
          <b>{QUARTER_LABELS[pageContext.department.dangerQuarter]} quart</b>,
          en termes de nombre de cas par habitant.
        </p>
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Demander un devis gratuit
          </CTA>
        </Box>
        <p>Cliquez sur votre ville :</p>
        <Box my={3}>
          <ul
            css={`
              color: #2a6496;
            `}
          >
            {pageContext.cities.map((city) => (
              <li>
                <Box my={1}>
                  <Link
                    to={
                      city.specialPageUrl ||
                      `/agent-de-securite/${slugify(
                        pageContext.department.region.nom
                      )}/${slugify(pageContext.department.nom)}/${slugify(
                        city
                      )}`
                    }
                    css={`
                      font-size: 1.15rem;
                    `}
                  >
                    {CITIES_WITH_MORE_THAN_50K_INHABITANTS.includes(
                      city.name || city
                    )
                      ? city.anchorText ||
                        `Agent de sécurité ${city.name || city}`
                      : city}
                  </Link>
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      </article>
    </ArticleContainer>
  );
}

function getRandomizedTerm({
  terms,
  index,
}: {
  terms: string[];
  index: number;
}) {
  return terms[index % terms.length];
}

const DepartmentTemplate = ({ pageContext, data, ...props }) => {
  const { site } = data;
  const slug = `${slugify(pageContext.department.region.nom)}/${slugify(
    pageContext.department.nom
  )}`;
  const shouldDisplayOnlyDepartementCode = pageContext.department.code === '79';
  const departementCodeAndNameOrOnlyDepartementCode = shouldDisplayOnlyDepartementCode
    ? pageContext.department.code
    : `${pageContext.department.code} (${pageContext.department.nom})`;
  const h1 = `${getRandomizedTerm({
    terms: ['Entreprise', 'Agence', 'Société'],
    index: pageContext.departmentRandomIndex,
  })} de sécurité dans le départment ${departementCodeAndNameOrOnlyDepartementCode}`;

  const title = `${getRandomizedTerm({
    terms: ['Entreprise', 'Agence', 'Société'],
    index: pageContext.departmentRandomIndex,
  })} de sécurité dans le départment ${departementCodeAndNameOrOnlyDepartementCode} - Lead Advisor`;

  const descriptionVariants = [
    `Recevez plusieurs devis gratuits pour des agents de sécurité dans le département ${pageContext.department.nom} en effectuant votre demande sur Lead Advisor Security. Réponses rapides sous 1 heure grâce à notre réseau comptant plus de 300 agences de sécurité privée.`,
    `Vous avez besoin de prestations de sécurité dans le département ${pageContext.department.nom} ? Demandez vos devis gratuit sur Lead Advisor Security en quelques minutes ! Nous répondrons très promptement à vos demandes d’agents de sécurité, de télésurveillance, de vidéosurveillance, de systèmes d’alarme, etc.`,
    `Vous recherchez une société de sécurité privée dans le département ${departementCodeAndNameOrOnlyDepartementCode} ? Recevez gratuitement vos devis sur Lead Advisor Security ! Nos agences de sécurité privée interviennent dans tout le département ${pageContext.department.code}.`,
    `Vous souhaitez sécuriser un événement, des locaux, ou une résidence dans le département ${departementCodeAndNameOrOnlyDepartementCode} ? Déposez votre demande sur Lead Advisor Security ! Nous vous établirons vos devis gratuits sous quelques heures.`,
    `Demandez vos devis gratuits pour des prestations de sécurité dans le département ${departementCodeAndNameOrOnlyDepartementCode} sur Lead Advisor Security : agents de sécurité, système de vidéosurveillance, service de télésurveillance, mise en place de systèmes d’alarme…`,
    `Besoin d’agent de sécurité dans le département ${departementCodeAndNameOrOnlyDepartementCode} ? Obtenez gratuitement et promptement vos devis pour des agents de sécurité sur Lead Advisor Security. Nos agences interviennent dans tout le département ${pageContext.department.code}.`,
  ];
  const description =
    descriptionVariants[
      pageContext.departmentRandomIndex % descriptionVariants.length
    ];

  const firstParagraphVariants = [
    <p>
      Vous recherchez une <strong>société de gardiennage</strong> dans le
      département {departementCodeAndNameOrOnlyDepartementCode} ? Nous pouvons
      répondre à vos besoins en <strong>agents de sécurité</strong> grâce à
      notre réseau comptant plus de 300 agences de sécurité privée.
    </p>,
    <p>
      Vous avez besoin de prestations de sécurité dans le département{' '}
      {departementCodeAndNameOrOnlyDepartementCode} et vous recherchez donc une{' '}
      <strong>société de sécurité privée</strong> ? Déposez votre{' '}
      <strong>demande de devis</strong> et recevez gratuitement vos chiffrages
      en quelques heures à peine.
    </p>,
    <p>
      Vous avez besoin d’<strong>agents de sécurité</strong> afin de sécuriser
      un évènement ou des locaux dans le département{' '}
      {departementCodeAndNameOrOnlyDepartementCode} ? Recevez vos devis gratuits
      sur Lead Advisor Security ! Nous répondons très promptement à vos demandes
      par le biais de notre réseau de plus de 300{' '}
      <strong>agences de sécurité</strong>.
    </p>,
    <p>
      Vous êtes à la recherche d’une{' '}
      <strong>entreprise de sécurité privée</strong> dans le département{' '}
      {departementCodeAndNameOrOnlyDepartementCode} ? Recevez gratuitement vos
      devis gratuits sous quelques minutes sur Lead Advisor Security ! Nos
      agences et leurs <strong>agents de sécurité</strong> interviennent dans
      tout le département {pageContext.department.code}.
    </p>,
  ];
  const secondParagraphVariants = [
    <>
      <p>
        Faites appel à des <strong>agents de sécurité</strong> afin de sécuriser
        votre entreprise, votre commerce, vos entrepôts, votre domicile, ou bien
        les évènements (conférence, exposition, salon, manifestation sportive,
        soirée privée…) que vous organisez dans le département{' '}
        {pageContext.department.nom} !
      </p>
      <p>
        Aucun autre service (vidéoprotection, télésurveillance, système
        d’alarme…) ne permet de sécuriser un lieu ou un évènement comme peut le
        faire un service de surveillance humaine. Il s’agit de la prestation de
        service la plus sûre pour vous, et la plus dissuasive pour les
        malfaiteurs. Le gardiennage s’avère de plus parfaitement complémentaire
        avec des systèmes de sécurité électronique.
      </p>
      <p>
        Le coût d’un agent de sécurité se révèle de plus très abordable (il faut
        compter environ 20€ HT de l’heure pour un Agent de Prévention et de
        Sécurité), que ce soit pour une entreprise ou pour un particulier. Ne
        prenez donc aucun risque et sécurisez votre activité, votre événement,
        ou votre résidence en faisant appel aux services d’agents de sécurité !
        Pour un coût très raisonnable, vous pourrez exercer votre activité ou
        profiter de votre évènement l’esprit serein.
      </p>
    </>,
    <>
      <p>
        En fonction de vos besoins, nos agences pourront vous fournir, dans le
        département {pageContext.department.nom}, le type d’agent de sécurité le
        plus adapté :
      </p>
      <ul>
        <li>
          <strong>
            Agent de Prévention et de Sécurité (APS) ou Agent de Sécurité (ADS)
          </strong>{' '}
          : indifféremment appelé APS ou ADS, il s’agit de l’agent de sécurité
          standard. Sa polyvalence lui permet d’accomplir diverses missions avec
          efficacité : filtrer et réaliser des fouilles d’effets personnels à
          l’entrée d’un site, surveiller un commerce, une demeure, ou des
          bureaux, etc.
        </li>

        <li>
          <strong>Agent de sécurité évènementiel</strong> : l’Agent de
          prévention et de sécurité évènementiel (APSE) est chargé d’assurer la
          sécurité de manifestations rassemblant un nombreux public, en plaçant
          les barrières et en vérifiant les billets et l’identité des visiteurs.
        </li>

        <li>
          <strong>Agent cynophile</strong> : un agent cynophile, également
          appelé “maître-chien”, conviendra particulièrement à la surveillance
          de sites sensibles, exposés aux squats, aux vols, ou au vandalisme,
          tels que les chantiers, les entrepôts, les commerces, les résidences,
          et les bureaux situés à proximité d’une cité ou d’une usine
          désaffectée.
        </li>

        <li>
          <strong>Agent d’accueil de contrôle d’accès</strong> : l’agent
          d’accueil s’occupe de la réception des visiteurs. Il vérifie leur
          identité et note leurs entrées et leurs sorties dans un registre, ou
          bien dans un logiciel. Il s’agit d’un véritable agent de sécurité
          ayant suivi une formation dans la sécurité, et non d’un hôte
          d’accueil. Les sociétés de sécurité privée n’ont d’ailleurs pas le
          droit de proposer des services d’hôtes et d’hôtesses.
        </li>

        <li>
          <strong>Rondier intervenant</strong> : un rondier patrouille en
          véhicule sur des sites tels qu’une usine, une zone industrielle, une
          zone commerciale, des entrepôts, etc. Ces rondes préventives
          s’adressent donc à des sites de grande superficie. Le rondier
          intervient également sur demande des téléopérateurs travaillent dans
          un centre de télésurveillance, afin d’aller effectuer la levée de
          doute sur un lieu pour lequel le système d’alarme s’est déclenché.
        </li>

        <li>
          <strong>Inspecteur de magasin</strong> : un agent de sécurité de
          magasin lutte contre la démarque inconnue en assurant une présence
          dissuasive dans les rayons ou bien à l’entrée de la boutique, et en
          détectant les comportements suspects, éventuellement à l’aide d’un
          système de vidéosurveillance.
        </li>

        <li>
          <strong>Agent incendie</strong> : cet agent possède un diplôme SSIAP
          (qui se décline sur 3 niveaux : SSIAP1, SSIAP2, et SSIAP3, par ordre
          d’expérience croissante), et se charge de vérifier et de maintenir le
          fonctionnement du matériel anti-incendie. Il s’assure également que
          les voies d’évacuation sont bien dégagées et se tient prêt pour faire
          face à un début d’incendie et prendre les mesures appropriées.
        </li>
      </ul>
    </>,
    <>
      <p>
        Des <strong>agents de sécurité</strong> vous apporteront une
        tranquillité d’esprit qui vous permettra de vous concentrer sur votre
        coeur de métier ou bien sur l’organisation de votre évènement (salon,
        conférence, cocktail, mariage, anniversaire…).
      </p>
      <p>
        Un service de <strong>surveillance humaine</strong> constitue une
        prestation à externaliser auprès d’une{' '}
        <strong>société de sécurité privée</strong>. En effet, les prix sont
        très abordables, et vous pourrez aisément maîtriser votre budget. De
        plus, vous bénéficierez de l’expertise et des conseils d’un
        professionnel.
      </p>
      <p>
        Une prestation de <strong>gardiennage</strong> complètera et bonifiera
        votre système d’alarme et de vidéosurveillance si vous en possédez déjà
        un. Elle vous apporte la garantie que des professionnels réagiront de
        façon appropriée en cas de situation de crise afin d’assurer votre
        sécurité.
      </p>
    </>,
    <>
      <p>
        Les <strong>entreprises de sécurité privée</strong> appartenant à notre
        réseau constitué de plus de 300 agences sauront vous conseiller et vous
        apporter leurs préconisations concernant le nombre et le type d’agents
        de sécurité que nécessite votre situation.
      </p>
      <p>
        En fonction de vos besoins (sécurisation de locaux d’entreprises, d’une
        résidence, d’un entrepôt, d’un chantier, ou d’une manifestation d’ordre
        professionnelle, privée, ou sportive), de la superficie à sécuriser, et
        du nombre d’employés, d’invités, ou de visiteurs, le nombre et le type
        d’agent de sécurité va effet beaucoup varier.
      </p>
      <p>
        Pour une surveillance nocturne d’un site industriel, d’un chantier,
        d’une résidence, ou d’un entrepôt, on pourra par exemple vous conseiller
        de faire appel à un <strong>agent cynophile</strong> (ou maître chien).
        Le chien sera en effet en mesure de détecter les anomalies grâce à ses
        sens aiguisés (notamment l’odorat et l’ouïe, mais aussi la vue qui
        s’avère très performante la nuit).
      </p>
      <p>
        Pour une manifestation ouverte au public, des{' '}
        <strong>agents de sécurité évènementiels</strong> seront tout indiqués.
        Ils seront en mesure de placer les barrières, de vérifier les effets
        personnels des visiteurs, et d’effectuer du filtrage à l’entrée, en
        vérifiant les billets ou les invitations.
      </p>
      <p>
        Pour protéger un commerce, des bureaux, ou une résidence, et en
        contrôler les accès, un ou plusieurs{' '}
        <strong>Agent de Prévention et de Sécurité</strong> (APS) sont
        recommandés. Leur polyvalence leur permettent d’accomplir plusieurs
        types de mission.
      </p>
    </>,
  ];

  const thirdAndFourthParagraphsVariants = [
    <>
      <h3>Qualité des prestations</h3>
      <Box my={4} />
      <p>
        Recevez des propositions de la part de{' '}
        <strong>société de sécurité privées</strong> agréés CNAPS (Conseil
        National des Activités Privées de Sécurité). Vous pourrez consulter leur
        profil, leurs certifications, et leurs recommandations.
      </p>
      <h3>
        À la recherche d’une société de sécurité dans le{' '}
        {departementCodeAndNameOrOnlyDepartementCode} ?
      </h3>
      <Box my={4} />
      <p>
        Votre demande de devis sera étudiée sous les plus brefs délais. En
        moyenne, vous recevrez un chiffrage sous une heure ouvrée. Afin
        d’établir un devis précis, veuillez indiquer le type et le nombre d’
        <strong>agents de sécurité</strong> dont vous avez besoin, les horaires
        et les dates de la prestation, ainsi que le contexte.
      </p>
    </>,
    <>
      <h3>Choix de votre agence de sécurité privée</h3>
      <Box my={4} />
      <p>
        En déposant une seule demande de devis sur Lead Advisor Security, vous
        recevrez en retour plusieurs propositions de la part d’
        <strong>entreprise de sécurité</strong> agréés CNAPS (Conseil National
        des Activités Privées de Sécurité). Vous pourrez consulter leur profil
        et échanger avec elles afin de faire le bon choix. De plus, Lead Advisor
        délivrer un label “Partenaire certifié” aux société de sécurité ayant
        transmis leur dossier administratif complet pour vérification.
      </p>
      <h3>
        Vous avez besoin des services d’une entreprise de sécurité privée dans
        le département {pageContext.department.code} (
        {pageContext.department.nom}) ?
      </h3>
      <Box my={4} />
      <p>
        Déposez votre demande sur Lead Advisor Security. Elle sera étudiée sous
        les meilleurs délais par nos agences partenaires. Vous recevez plusieurs
        propositions dans la journée, voire dans l’heure ! Pour obtenir un devis
        précis, indiquez le type et le nombre d’
        <strong>agents de sécurité</strong> que vous souhaitez. Décrivez
        également le contexte de votre demande (quel est lieu à sécuriser ?).
        N’hésitez pas à demander conseil aux prestataires en échangeant avec
        eux. Vous pouvez également appeler au <strong>07 60 48 75 74</strong>{' '}
        pour être conseillé.
      </p>
    </>,
    <>
      <h3>
        Vous avez rapidement besoin d’un chiffrage pour une intervention dans le{' '}
        {pageContext.department.code} (département {pageContext.department.nom})
        ?
      </h3>
      <Box my={4} />
      <p>
        Demandez votre devis en quelques minutes sur Lead Advisor Security, par
        le biais de notre QCM adaptif. Vous recevrez ensuite vos chiffrages sous
        quelques heures, voire quelques minutes ! Vous pouvez être très précis
        dans votre demande, en indiquant le nombre et le type d’
        <strong>agents de sécurité</strong> désirés. Si vous avez besoin de
        conseils, vous pouvez aussi échanger avec les{' '}
        <strong>agences de sécurité privée</strong> qui vous répondront.
      </p>
      <h3>Fiabilité des services</h3>
      <Box my={4} />
      <p>
        Lors de vos échanges avec les prestataires, vous pourrez évaluer leur
        réactivité, leur fiabilité, et leur professionnalisme. Lead Advisor
        Security vous donne également la possibilité de consulter leur profil et
        leurs certifications, afin que vous puissiez faire le bon choix.
      </p>
    </>,
    <>
      <h3>
        Vous souhaitez obtenir un devis pour des services d’agents de sécurité
        dans le département {pageContext.department.code} (
        {pageContext.department.nom}) ?
      </h3>
      <Box my={4} />
      <p>
        Déposez votre demande de devis en deux minutes sur Lead Advisor Security
        via un QCM rapide et intuitif ! En effectuant une seule demande, vous
        recevez plusieurs propositions de la part de nos{' '}
        <strong>agences de sécurité</strong> partenaires. Vous pourrez ainsi
        comparer les prestations proposées.{' '}
      </p>
      <h3>Choix de votre prestataire</h3>
      <Box my={4} />
      <p>
        Pour bien choisir votre <strong>société de sécurité</strong>, la
        plateforme Lead Advisor Security vous permet de consulter le profil des
        entreprises qui vous ont répondu et d’échanger avec elles afin d’être
        conseillé et d’établir des contacts commerciaux qui vous permettront
        d’évaluer la fiabilité des agences candidates et d’effectuer le bon
        choix.
      </p>
    </>,
  ];
  const permutations = cartesian(
    firstParagraphVariants,
    secondParagraphVariants,
    thirdAndFourthParagraphsVariants
  );
  // const permutationsIndices = cartesian(
  //   firstParagraphVariants.map((_, i) => i + 1),
  //   secondParagraphVariants.map((_, i) => i + 1),
  //   thirdAndFourthParagraphsVariants.map((_, i) => i + 1)
  // );
  // console.log({
  //   departementCode: pageContext.department.code,
  //   departementName: pageContext.department.nom,
  //   combinaison:
  //     permutationsIndices[
  //       pageContext.departmentRandomIndex % permutationsIndices.length
  //     ],
  //   departmentRandomIndex: pageContext.departmentRandomIndex,
  // });

  const [
    firstParagraph,
    secondParagraph,
    thirdAndFourthParagraphs,
  ] = permutations[pageContext.departmentRandomIndex % permutations.length];

  return (
    <Layout
      sidebarContent={null}
      rightsidebarContent={
        <div className="flex justify-center py-2 mt-12 shadow-md xl:shadow-none bg-indigo-50 xl:bg-transparent xl:mr-8 xl:p-0">
          <a
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            <Button variant="secondary" className="xl:animate-pump">
              Demander un devis gratuit
            </Button>
          </a>
        </div>
      }
    >
      <TemplateContent
        h1={h1}
        firstParagraph={firstParagraph}
        secondParagraph={secondParagraph}
        thirdAndFourthParagraphs={thirdAndFourthParagraphs}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{title}</title>
            <link
              rel="canonical"
              href={`${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slug}`}
            />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta
              property="og:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="author" content="David Khoy de Lead Advisor" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@lead_advisor" />
            <meta name="twitter:title" content={title} />
            <meta
              name="twitter:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="/img/og-image.jpg" />

            <script type="application/ld+json">
              {`
{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite",
    "name": "Agent de sécurité"
  },{
    "@type": "ListItem",
    "position": 2,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}",
    "name": "${pageContext.department.region.nom}"
  },{
    "@type": "ListItem",
    "position": 3,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}/${slugify(pageContext.department.nom)}",
    "name": "${pageContext.department.nom}"
  }]
}
`}
            </script>
          </Helmet>
        }
        pageContext={pageContext}
        data={data}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query departmentSiteMetadata {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default DepartmentTemplate;
